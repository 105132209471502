import React, { useEffect, useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { ContactSupport } from "../merlinv1/beta_api";

function ContactSupportPartial() {
  const [email, setEmail] = useState("");
  const [area, setArea] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [screen, setScreen] = useState(0);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleContactSupport = async () => {
    setIsLoading(true);
    var res = await ContactSupport(email, area, description);
    setIsLoading(false);
    setScreen(1);
  };

  if (screen === 0) {
    return (
      <section className='pt-16'>
        <div className='flex flex-row justify-center'>
          <span className='text-blue-accent font-medium text-4xl'>
            Contact Quasi Support
          </span>
        </div>
        <div className='lg:px-32 md:px-16 sm:px-8 pt-8'>
          <input
            className='w-full rounded-xl mb-8 py-2 pl-6 bg-gray-level1'
            placeholder='Email'
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className='w-full rounded-xl mb-8 py-2 pl-6 bg-gray-level1'
            placeholder='Which Area Are You Seeing An Issue With?'
            onChange={(e) => setArea(e.target.value)}
          />
          <input
            className='w-full rounded-xl mb-8 py-2 pl-6 bg-gray-level1'
            placeholder='Describe The Situation In Detail'
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className='pb-8'>
            {isLoading ? (
              <button
                className='btn-sm text-blue-text bg-blue-level1 hover:bg-blue-level2 hover:text-blue-text2 rounded-xl'
                type='submit'>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 23 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='animate-spin-slow'>
                  <path
                    d='M12.2129 1.89062C12.2129 1.30469 11.7246 0.806641 11.1387 0.806641C10.5527 0.806641 10.0645 1.30469 10.0645 1.89062V4.87891C10.0645 5.45508 10.5527 5.95312 11.1387 5.95312C11.7246 5.95312 12.2129 5.45508 12.2129 4.87891V1.89062ZM5.29883 7.61328C5.70898 8.02344 6.41211 8.0332 6.8125 7.62305C7.23242 7.21289 7.23242 6.50977 6.82227 6.09961L4.76172 4.0293C4.35156 3.61914 3.6582 3.59961 3.24805 4.00977C2.83789 4.41992 2.82812 5.12305 3.23828 5.5332L5.29883 7.61328ZM15.3672 6.16797C14.9668 6.57812 14.9668 7.28125 15.377 7.69141C15.7871 8.10156 16.4902 8.0918 16.9004 7.68164L18.9707 5.61133C19.3809 5.20117 19.3809 4.49805 18.9609 4.07812C18.5605 3.67773 17.8574 3.67773 17.4473 4.08789L15.3672 6.16797ZM21.168 12.9844C21.7539 12.9844 22.252 12.4961 22.252 11.9102C22.252 11.3242 21.7539 10.8359 21.168 10.8359H18.1895C17.6035 10.8359 17.1055 11.3242 17.1055 11.9102C17.1055 12.4961 17.6035 12.9844 18.1895 12.9844H21.168ZM1.09961 10.8359C0.513672 10.8359 0.0253906 11.3242 0.0253906 11.9102C0.0253906 12.4961 0.513672 12.9844 1.09961 12.9844H4.09766C4.68359 12.9844 5.18164 12.4961 5.18164 11.9102C5.18164 11.3242 4.68359 10.8359 4.09766 10.8359H1.09961ZM16.9004 16.1289C16.4902 15.7285 15.7871 15.7285 15.3672 16.1387C14.9668 16.5488 14.9668 17.252 15.377 17.6621L17.457 19.7324C17.8672 20.1426 18.5703 20.1328 18.9805 19.7227C19.3906 19.3223 19.3809 18.6094 18.9707 18.1992L16.9004 16.1289ZM3.30664 18.1992C2.88672 18.6094 2.88672 19.3125 3.28711 19.7227C3.69727 20.1328 4.40039 20.1426 4.81055 19.7324L6.88086 17.6719C7.30078 17.2617 7.30078 16.5586 6.90039 16.1484C6.49023 15.7383 5.78711 15.7285 5.37695 16.1387L3.30664 18.1992ZM12.2129 18.9512C12.2129 18.3652 11.7246 17.8672 11.1387 17.8672C10.5527 17.8672 10.0645 18.3652 10.0645 18.9512V21.9395C10.0645 22.5156 10.5527 23.0137 11.1387 23.0137C11.7246 23.0137 12.2129 22.5156 12.2129 21.9395V18.9512Z'
                    fill='#003150'
                  />
                </svg>
              </button>
            ) : (
              <button
                className='bg-blue-level1 text-blue-text hover:bg-blue-level2 rounded-xl py-2 px-6'
                onClick={() => handleContactSupport()}>
                Submit
              </button>
            )}
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className='pt-16'>
        <div className='flex flex-row justify-center'>
          <div className='flex flex-col'>
            <span className='text-blue-accent font-medium text-4xl text-center'>
              Thanks!
            </span>
            <span className='text-center'>
              A Quasi team member will be reaching out to you soon.
            </span>
          </div>
        </div>
        <div className='flex justify-center pt-8'>
          <button
            className='btn-sm bg-blue-level1 text-blue-text hover:bg-blue-level2 rounded-xl py-2 px-6'
            onClick={() => history.push("/dashboard")}>
            Go To Dashboard
          </button>
        </div>
      </section>
    );
  }
}

export default ContactSupportPartial;
