import React from "react";
import { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";
import CreatePartial from "../partials/CreatePartial";

const Home = (props) => {
  // Sets screen analytics
  useEffect(() => {}, []);
  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <MetaTags>
        <title>Quasi Business | Create</title>
        <meta name='description' content='Quasi Business Create' />
        <meta property='og:title' content='Quasi Business | Create' />
      </MetaTags>

      {/*  Site header */}

      {/*  Page content */}
      <main className='flex-grow'>
        {/*  Page sections */}
        <Header />

        <CreatePartial />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
};

export default Home;
