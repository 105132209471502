import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { Cookies } from "react-cookie";

const firebaseConfig = {
  apiKey: "AIzaSyADzV-zNU7C3evaOjP7O0u-z8j6GiEllaQ",
  authDomain: "quasi-business.firebaseapp.com",
  projectId: "quasi-business",
  storageBucket: "quasi-business.appspot.com",
  messagingSenderId: "518325658762",
  appId: "1:518325658762:web:4d36254e332408ad787973",
  measurementId: "G-4KXFWNLV0M",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
