import TextArea from "../pages/components/TextArea";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

function CreatePartial() {
  const history = useHistory();

  return (
    <section>
      <div className='pt-24 px-24'>
        <div className='flex flex-col justify-center'>
          <span className='text-center text-blue-accent text-4xl font-semibold'>
            Generate Content
          </span>
          <span className='text-center text-xl font-medium'>
            Select the type of content you wish to make.
          </span>
        </div>
        <div className='grid grid-cols-2 grid-rows-2 gap-4 pt-8'>
          <div
            className='bg-gray-level1 hover:bg-gray-level2 rounded-xl p-4 p-4'
            onClick={() => history.push("/studio/social")}>
            <div className='flex flex-row'>
              <div className='flex flex-col justify-center'>
                <div className='bg-blue-level2 p-4 rounded-xl'>
                  <svg
                    width='26'
                    height='26'
                    viewBox='0 0 26 26'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M21.3281 8.70605C23.623 8.70605 25.5396 6.80176 25.5396 4.50684C25.5396 2.19971 23.623 0.307617 21.3281 0.307617C19.0332 0.307617 17.1289 2.19971 17.1289 4.50684C17.1289 6.80176 19.0332 8.70605 21.3281 8.70605ZM0.612793 18.3008C0.612793 20.6323 0.881348 22.3535 2.13867 23.6108C3.4082 24.8682 5.12939 25.1489 7.46094 25.1489H16.7261C19.0454 25.1489 20.7788 24.8682 22.0483 23.6108C23.3057 22.3413 23.5742 20.6323 23.5742 18.3008V10.1587C22.9395 10.4272 22.2314 10.5615 21.499 10.5493C21.2793 10.5615 21.0596 10.5371 20.8521 10.5005V18.6426C20.8521 19.9243 20.6812 20.9741 20.0586 21.6211C19.4238 22.2559 18.3374 22.4268 17.0679 22.4268H7.11914C5.84961 22.4268 4.76318 22.2437 4.12842 21.6211C3.49365 20.9741 3.33496 19.9243 3.33496 18.6426V8.7915C3.33496 7.48535 3.49365 6.42334 4.12842 5.78857C4.76318 5.15381 5.84961 4.98291 7.14355 4.98291H15.3223C15.2856 4.76318 15.2734 4.55566 15.2734 4.33594C15.2612 3.60352 15.4077 2.89551 15.6641 2.26074H7.43652C5.1416 2.26074 3.396 2.5415 2.13867 3.79883C0.881348 5.06836 0.612793 6.77734 0.612793 9.07227V18.3008Z'
                      fill='#003150'
                    />
                  </svg>
                </div>
              </div>
              <div className='flex flex-col'>
                <span className='text-xl font-semibold pl-4'>Post</span>
                <span className='text-md font-medium pl-4 text-gray-text1'>
                  New social media posts made based on your intent.
                </span>
              </div>
            </div>
          </div>
          <div
            className='bg-gray-level1 hover:bg-gray-level2 rounded-xl p-4 p-4'
            onClick={() => history.push("/studio/photo")}>
            <div className='flex flex-row'>
              <div className='flex flex-col justify-center'>
                <div className='bg-blue-level2 p-4 rounded-xl'>
                  <svg
                    width='26'
                    height='26'
                    viewBox='0 0 30 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.75098 23.21H25.4907C28.1885 23.21 29.6289 21.7817 29.6289 19.1084V4.28906C29.6289 1.61572 28.1885 0.1875 25.4907 0.1875H4.75098C2.05322 0.1875 0.612793 1.60352 0.612793 4.28906V19.1084C0.612793 21.7939 2.05322 23.21 4.75098 23.21ZM3.33496 4.56982C3.33496 3.44678 3.90869 2.90967 4.98291 2.90967H25.2588C26.3208 2.90967 26.9067 3.44678 26.9067 4.56982V17.0332L21.1206 11.6133C20.5835 11.1372 19.9487 10.8809 19.3018 10.8809C18.6304 10.8809 18.0322 11.1128 17.4707 11.6011L11.9653 16.5205L9.71924 14.4941C9.20654 14.0425 8.64502 13.7983 8.07129 13.7983C7.49756 13.7983 7.00928 14.0303 6.49658 14.4819L3.33496 17.3018V4.56982ZM10.3418 12.0039C11.9653 12.0039 13.2959 10.6733 13.2959 9.0376C13.2959 7.41406 11.9653 6.05908 10.3418 6.05908C8.70605 6.05908 7.37549 7.41406 7.37549 9.0376C7.37549 10.6733 8.70605 12.0039 10.3418 12.0039Z'
                      fill='#003150'
                    />
                  </svg>
                </div>
              </div>
              <div className='flex flex-col'>
                <span className='text-xl font-semibold pl-4'>Image</span>
                <span className='text-md font-medium pl-4 text-gray-text1'>
                  Create stunning imagery and stock photos for your business.
                </span>
              </div>
            </div>
          </div>
          <div
            className='bg-gray-level1 hover:bg-gray-level2 rounded-xl p-4 p-4'
            onClick={() => history.push("/studio/new/=twitter")}>
            <div className='flex flex-row'>
              <div className='flex flex-col justify-center'>
                <div className='bg-blue-level2 p-4 rounded-xl'>
                  <svg
                    width='29'
                    height='28'
                    viewBox='0 0 29 28'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M0.112793 13.5522C0.112793 16.5063 1.56543 18.3008 4.27539 18.7036L6.8999 24.502C7.97412 26.9067 9.91504 27.0898 11.2578 26.626C12.564 26.1743 13.3696 24.9414 13.04 23.2446L12.2466 19.2041C15.4814 19.8633 18.7163 21.0474 21.7681 22.793C22.2686 24.0503 23.4404 24.856 24.9297 24.856C26.9194 24.856 28.3354 23.4277 28.3354 21.4502V3.82324C28.3354 1.8457 26.9072 0.41748 24.9297 0.41748C23.3916 0.41748 22.1831 1.28418 21.7314 2.59033C18.0938 4.73877 13.4795 6.15479 9.80518 6.56982H5.54492C2.0293 6.56982 0.112793 8.42529 0.112793 11.8188V13.5522ZM24.063 21.4502V3.82324C24.063 3.31055 24.417 2.95654 24.9297 2.95654C25.4424 2.95654 25.7964 3.31055 25.7964 3.82324V21.4502C25.7964 21.9629 25.4424 22.3169 24.9297 22.3169C24.417 22.3169 24.063 21.9629 24.063 21.4502ZM10.3179 16.3354V9.01123C14.334 8.52295 18.1304 7.37549 21.5239 5.52002V19.8511C18.0693 17.959 14.3096 16.8237 10.3179 16.3354ZM5.54492 16.2622C3.44531 16.2622 2.65186 15.5176 2.65186 13.5522V11.8188C2.65186 9.84131 3.44531 9.10889 5.54492 9.10889H7.77881V16.2622H5.54492ZM9.3291 23.855L6.94873 18.7891H9.71973L9.74414 18.8013L10.6719 23.501C10.7451 23.9038 10.5376 24.1724 10.2446 24.2822C9.93945 24.3799 9.53662 24.2944 9.3291 23.855Z'
                      fill='#003150'
                    />
                  </svg>
                </div>
              </div>
              <div className='flex flex-col'>
                <span className='text-xl font-semibold pl-4'>Awareness</span>
                <span className='text-md font-medium pl-4 text-gray-text1'>
                  Post content to get people talking about you.
                </span>
              </div>
            </div>
          </div>
          <div
            className='bg-gray-level1 hover:bg-gray-level2 rounded-xl p-4 p-4'
            onClick={() => history.push("/studio/specific-post")}>
            <div className='flex flex-row'>
              <div className='flex flex-col justify-center'>
                <div className='bg-blue-level2 p-4 rounded-xl'>
                  <svg
                    width='22'
                    height='28'
                    viewBox='0 0 22 28'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M0.612793 22.8662C0.612793 25.564 2.02881 27.0044 4.71436 27.0044H17.7515C20.4248 27.0044 21.853 25.564 21.853 22.8662V4.51904C21.853 1.82129 20.4248 0.393066 17.7515 0.393066H4.71436C2.02881 0.393066 0.612793 1.82129 0.612793 4.51904V22.8662ZM3.33496 22.6343V4.75098C3.33496 3.68896 3.87207 3.10303 4.99512 3.10303H17.4707C18.5938 3.10303 19.1309 3.68896 19.1309 4.75098V22.6343C19.1309 23.7085 18.5938 24.2822 17.4707 24.2822H4.99512C3.87207 24.2822 3.33496 23.7085 3.33496 22.6343ZM14.2725 12.7344C15.6641 12.7344 16.6528 11.77 16.6528 10.3784C16.6528 9.02344 15.8105 8.13232 14.6021 8.13232C13.9917 8.13232 13.4912 8.32764 13.1494 8.75488H12.9053C12.9907 8.36426 13.2471 7.97363 13.6255 7.64404C13.9795 7.33887 14.4312 7.11914 14.9683 7.00928C15.3589 6.92383 15.5054 6.78955 15.5054 6.521C15.5054 6.26465 15.3223 6.08154 14.9927 6.08154C14.2236 6.08154 13.2837 6.55762 12.6489 7.229C11.9897 7.9248 11.6846 8.84033 11.6846 9.80469C11.6846 11.6602 12.8198 12.7344 14.2725 12.7344ZM8.38867 12.7466C9.78027 12.7466 10.7812 11.7822 10.7812 10.3784C10.7812 9.03564 9.92676 8.13232 8.71826 8.13232C8.10791 8.13232 7.61963 8.32764 7.26562 8.75488H7.02148C7.10693 8.36426 7.36328 7.97363 7.7417 7.65625C8.10791 7.33887 8.54736 7.11914 9.08447 7.02148C9.46289 6.94824 9.62158 6.80176 9.62158 6.521C9.62158 6.27686 9.43848 6.09375 9.10889 6.09375C8.35205 6.09375 7.42432 6.54541 6.76514 7.24121C6.11816 7.93701 5.80078 8.84033 5.80078 9.81689C5.80078 11.6602 6.94824 12.7466 8.38867 12.7466ZM6.875 16.8726H15.603C16.0547 16.8726 16.3843 16.5186 16.3843 16.0791C16.3843 15.6396 16.0547 15.2979 15.603 15.2979H6.875C6.41113 15.2979 6.06934 15.6396 6.06934 16.0791C6.06934 16.5186 6.41113 16.8726 6.875 16.8726ZM6.875 20.8521H11.1597C11.6113 20.8521 11.9531 20.5103 11.9531 20.0708C11.9531 19.6191 11.6113 19.2651 11.1597 19.2651H6.875C6.41113 19.2651 6.06934 19.6191 6.06934 20.0708C6.06934 20.5103 6.41113 20.8521 6.875 20.8521Z'
                      fill='#003150'
                    />
                  </svg>
                </div>
              </div>
              <div className='flex flex-col'>
                <span className='text-xl font-semibold pl-4'>
                  Specific Post
                </span>
                <span className='text-md font-medium pl-4 text-gray-text1'>
                  Share your revolutionary ideas with the world.
                </span>
              </div>
            </div>
          </div>
          <div
            className='bg-gray-level1 hover:bg-gray-level2 rounded-xl p-4 p-4'
            onClick={() => history.push("/studio/blog")}>
            <div className='flex flex-row'>
              <div className='flex flex-col justify-center'>
                <div className='bg-blue-level2 p-4 rounded-xl'>
                  <svg
                    width='22'
                    height='28'
                    viewBox='0 0 22 28'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M0.612793 22.8662C0.612793 25.564 2.02881 27.0044 4.71436 27.0044H17.7515C20.4248 27.0044 21.853 25.564 21.853 22.8662V4.51904C21.853 1.82129 20.4248 0.393066 17.7515 0.393066H4.71436C2.02881 0.393066 0.612793 1.82129 0.612793 4.51904V22.8662ZM3.33496 22.6343V4.75098C3.33496 3.68896 3.87207 3.10303 4.99512 3.10303H17.4707C18.5938 3.10303 19.1309 3.68896 19.1309 4.75098V22.6343C19.1309 23.7085 18.5938 24.2822 17.4707 24.2822H4.99512C3.87207 24.2822 3.33496 23.7085 3.33496 22.6343ZM6.85059 7.27783H15.957C16.4087 7.27783 16.7505 6.92383 16.7505 6.47217C16.7505 6.03271 16.4087 5.69092 15.957 5.69092H6.85059C6.37451 5.69092 6.04492 6.03271 6.04492 6.47217C6.04492 6.92383 6.37451 7.27783 6.85059 7.27783ZM6.85059 10.7446H12.1606C12.6123 10.7446 12.9541 10.3906 12.9541 9.95117C12.9541 9.51172 12.6123 9.16992 12.1606 9.16992H6.85059C6.37451 9.16992 6.04492 9.51172 6.04492 9.95117C6.04492 10.3906 6.37451 10.7446 6.85059 10.7446ZM7.02148 22.1216H15.4688C16.4941 22.1216 17.019 21.5967 17.019 20.5835V14.187C17.019 13.1738 16.4941 12.6367 15.4688 12.6367H7.02148C6.03271 12.6367 5.45898 13.1738 5.45898 14.187V20.5835C5.45898 21.5967 6.03271 22.1216 7.02148 22.1216Z'
                      fill='#003150'
                    />
                  </svg>
                </div>
              </div>
              <div className='flex flex-col justify-center'>
                <span className='text-xl font-semibold pl-4'>Blog</span>
                <span className='text-md font-medium pl-4 text-gray-text1'>
                  Write Blogs for your audience.
                </span>
              </div>
            </div>
          </div>
          <div className='bg-gray-level1 rounded-xl p-4 p-4'>
            <div className='flex flex-row'>
              <div className='flex flex-col justify-center'>
                <div className='bg-gray-level2 p-4 rounded-xl'>
                  <svg
                    width='27'
                    height='27'
                    viewBox='0 0 27 27'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M13.417 26.4185C20.3994 26.4185 26.1489 20.6689 26.1489 13.6865C26.1489 6.71631 20.3872 0.95459 13.4048 0.95459C6.43457 0.95459 0.685059 6.71631 0.685059 13.6865C0.685059 20.6689 6.43457 26.4185 13.417 26.4185ZM13.417 23.623C7.91162 23.623 3.49268 19.1919 3.49268 13.6865C3.49268 8.18115 7.89941 3.75 13.4048 3.75C18.9224 3.75 23.3413 8.18115 23.3535 13.6865C23.3657 19.1919 18.9224 23.623 13.417 23.623ZM13.417 21.3647C17.6162 21.3647 21.083 17.8735 21.083 13.6865C21.083 9.4873 17.6284 6.0083 13.417 6.0083C9.21777 6.0083 5.75098 9.49951 5.75098 13.6865C5.75098 17.8735 9.24219 21.3647 13.417 21.3647ZM13.417 18.8989C10.5972 18.8989 8.20459 16.5063 8.20459 13.6865C8.20459 10.8545 10.585 8.47412 13.417 8.47412C16.2368 8.47412 18.6294 10.8667 18.6294 13.6865C18.6294 16.5186 16.249 18.8989 13.417 18.8989ZM13.4292 16.6528C15.0405 16.6528 16.3833 15.3101 16.3833 13.6865C16.3833 12.0752 15.0405 10.7324 13.4292 10.7324C11.7935 10.7324 10.4629 12.063 10.4629 13.6865C10.4629 15.3101 11.8057 16.6528 13.4292 16.6528Z'
                      fill='#A2A2A2'
                    />
                  </svg>
                </div>
              </div>
              <div className='flex flex-col'>
                <span className='text-xl font-semibold text-gray-text1 pl-4'>
                  Campaign
                </span>
                <span className='text-md font-medium pl-4 text-gray-text1'>
                  Showcase your new products/services in engaging posts.{" "}
                </span>
              </div>
            </div>
          </div>
          <div className='bg-gray-level1 rounded-xl p-4 p-4'>
            <div className='flex flex-row'>
              <div className='flex flex-col justify-center'>
                <div className='bg-gray-level2 p-4 rounded-xl'>
                  <svg
                    width='26'
                    height='26'
                    viewBox='0 0 20 28'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M10.2812 17.897C12.7837 17.897 14.7246 16.0171 14.7246 13.2339V4.98193C14.7246 2.19873 12.7837 0.318848 10.2812 0.318848C7.7666 0.318848 5.82568 2.19873 5.82568 4.98193V13.2339C5.82568 16.0171 7.7666 17.897 10.2812 17.897ZM0.588867 13.5635C0.588867 18.7759 4.08008 22.2793 9.01172 22.792V25.0381H4.56836C3.83594 25.0381 3.23779 25.624 3.23779 26.3442C3.23779 27.0645 3.83594 27.6626 4.56836 27.6626H15.9941C16.7144 27.6626 17.3247 27.0645 17.3247 26.3442C17.3247 25.624 16.7144 25.0381 15.9941 25.0381H11.5386V22.792C16.4824 22.2793 19.9614 18.7759 19.9614 13.5635V11.1953C19.9614 10.4751 19.3755 9.91357 18.6431 9.91357C17.9106 9.91357 17.3125 10.4751 17.3125 11.1953V13.478C17.3125 17.6284 14.4194 20.3628 10.2812 20.3628C6.13086 20.3628 3.25 17.6284 3.25 13.478V11.1953C3.25 10.4751 2.63965 9.91357 1.91943 9.91357C1.18701 9.91357 0.588867 10.4751 0.588867 11.1953V13.5635Z'
                      fill='#A2A2A2'
                    />
                  </svg>
                </div>
              </div>
              <div className='flex flex-col'>
                <span className='text-xl font-semibold text-gray-text1 pl-4'>
                  Audio
                </span>
                <span className='text-md font-medium pl-4 text-gray-text1'>
                  Create engaging posts around your audio content.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreatePartial;
