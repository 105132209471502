import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
// import dislikeOutput from "../merlinv1/beta_api";
import { uploadFileToFirebase } from "../merlinv1/beta_api";

function UploadFilePartial() {
  const [file, setFile] = useState("");
  const fileInputRef = useRef();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadText, setUploadText] = useState("Upload");

  const handleChange = async (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    setUploadText("Uploading...");
    setIsUploading(true);
    var audioBase64;
    event.preventDefault();
    // Convert audio file to base64 encoding
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      audioBase64 = reader.result.split(",")[1];
      const res = await uploadFileToFirebase(audioBase64, file.name);
      console.log(res);
      setIsUploading(false);
      setUploadText("Re-Upload");
    };
  };

  return (
    <section className='pt-16 pb-16'>
      <div className='flex flex-row justify-center'>
        <div className='flex flex-col'>
          <span className='text-orange-accent text-center font-medium text-4xl'>
            Upload File
          </span>
          <span className='text-center'>Upload your file!</span>
          <form onSubmit={handleSubmit}>
            <div className='bg-gray-level1 hover:bg-gray-level2 rounded-xl p-6'>
              <button
                onClick={() => fileInputRef.current.click()}
                type='button'>
                <div className='flex flex-row justify-center pb-6'>
                  <svg
                    width='64'
                    height='55'
                    viewBox='0 0 64 55'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M38.7734 36.7002V42.1445H50.834C58.5732 42.1445 63.4072 37.5791 63.4072 31.2314C63.4072 25.9092 60.4287 21.6367 55.5459 19.708C55.5459 8.5752 47.5137 0.542969 37.1377 0.542969C30.6924 0.542969 25.7363 3.79004 22.5869 8.30664C16.6055 6.89062 9.76953 11.3584 9.47656 18.2676C3.83691 19.3174 0.370117 24.0293 0.370117 30.0107C0.370117 36.7246 5.52148 42.1445 13.7246 42.1445H24.7354V36.7002H13.7246C8.69531 36.7002 5.8877 33.624 5.8877 29.8643C5.8877 25.3232 8.93945 22.2959 13.8223 22.2959C14.1641 22.2959 14.335 22.1006 14.3105 21.7344C14.0664 14.6055 19.1445 12.1152 24.54 13.6777C24.8574 13.751 25.0771 13.7021 25.2236 13.4092C27.543 9.13672 31.1562 5.9873 37.0889 5.9873C44.584 5.9873 49.9551 11.8955 50.3213 18.9268C50.3945 20.3184 50.2969 21.8076 50.1748 23.0283C50.126 23.3701 50.2725 23.5654 50.6143 23.6143C55.1064 24.3955 57.8652 26.8857 57.8652 30.8652C57.8652 34.3076 55.5215 36.7002 50.6631 36.7002H38.7734ZM31.7666 54.2051C33.1338 54.2051 34.2568 53.0576 34.2568 51.7393V29.498L33.9639 25.25L35.4531 27.0811L38.6758 30.3525C39.1152 30.792 39.7256 31.0605 40.3359 31.0605C41.5078 31.0605 42.5332 30.1816 42.5332 28.9365C42.5332 28.3018 42.3135 27.8135 41.8496 27.374L33.6953 19.708C33.0361 19.0977 32.4258 18.8535 31.7666 18.8535C31.083 18.8535 30.4971 19.0977 29.8135 19.708L21.6592 27.374C21.1953 27.8135 20.9756 28.3018 20.9756 28.9365C20.9756 30.1816 21.9766 31.0605 23.1729 31.0605C23.7832 31.0605 24.3936 30.792 24.833 30.3525L28.0557 27.0811L29.5693 25.25L29.252 29.498V51.7393C29.252 53.0576 30.375 54.2051 31.7666 54.2051Z'
                      fill='#F5821F'
                    />
                  </svg>
                </div>
                <div className='flex flex-col pt-2'>
                  <span className='font-medium'>
                    Drop Video/Audio file here or click the icon
                  </span>
                  {file !== "" ? (
                    <span className='font-medium text-gray-level4'>
                      {file.name}
                    </span>
                  ) : null}
                </div>
              </button>
              <input
                onChange={handleChange}
                multiple={false}
                ref={fileInputRef}
                type='file'
                hidden
              />
            </div>
            <div className='flex flex-row justify-center pt-8'>
              <button
                type='submit'
                className='bg-orange-level1 hover:bg-orange-level2 text-orange-text rounded-xl p-2'>
                {isUploading ? (
                  <div className='flex flex-row'>
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 23 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='animate-spin-slow'>
                      <path
                        d='M12.2129 1.89062C12.2129 1.30469 11.7246 0.806641 11.1387 0.806641C10.5527 0.806641 10.0645 1.30469 10.0645 1.89062V4.87891C10.0645 5.45508 10.5527 5.95312 11.1387 5.95312C11.7246 5.95312 12.2129 5.45508 12.2129 4.87891V1.89062ZM5.29883 7.61328C5.70898 8.02344 6.41211 8.0332 6.8125 7.62305C7.23242 7.21289 7.23242 6.50977 6.82227 6.09961L4.76172 4.0293C4.35156 3.61914 3.6582 3.59961 3.24805 4.00977C2.83789 4.41992 2.82812 5.12305 3.23828 5.5332L5.29883 7.61328ZM15.3672 6.16797C14.9668 6.57812 14.9668 7.28125 15.377 7.69141C15.7871 8.10156 16.4902 8.0918 16.9004 7.68164L18.9707 5.61133C19.3809 5.20117 19.3809 4.49805 18.9609 4.07812C18.5605 3.67773 17.8574 3.67773 17.4473 4.08789L15.3672 6.16797ZM21.168 12.9844C21.7539 12.9844 22.252 12.4961 22.252 11.9102C22.252 11.3242 21.7539 10.8359 21.168 10.8359H18.1895C17.6035 10.8359 17.1055 11.3242 17.1055 11.9102C17.1055 12.4961 17.6035 12.9844 18.1895 12.9844H21.168ZM1.09961 10.8359C0.513672 10.8359 0.0253906 11.3242 0.0253906 11.9102C0.0253906 12.4961 0.513672 12.9844 1.09961 12.9844H4.09766C4.68359 12.9844 5.18164 12.4961 5.18164 11.9102C5.18164 11.3242 4.68359 10.8359 4.09766 10.8359H1.09961ZM16.9004 16.1289C16.4902 15.7285 15.7871 15.7285 15.3672 16.1387C14.9668 16.5488 14.9668 17.252 15.377 17.6621L17.457 19.7324C17.8672 20.1426 18.5703 20.1328 18.9805 19.7227C19.3906 19.3223 19.3809 18.6094 18.9707 18.1992L16.9004 16.1289ZM3.30664 18.1992C2.88672 18.6094 2.88672 19.3125 3.28711 19.7227C3.69727 20.1328 4.40039 20.1426 4.81055 19.7324L6.88086 17.6719C7.30078 17.2617 7.30078 16.5586 6.90039 16.1484C6.49023 15.7383 5.78711 15.7285 5.37695 16.1387L3.30664 18.1992ZM12.2129 18.9512C12.2129 18.3652 11.7246 17.8672 11.1387 17.8672C10.5527 17.8672 10.0645 18.3652 10.0645 18.9512V21.9395C10.0645 22.5156 10.5527 23.0137 11.1387 23.0137C11.7246 23.0137 12.2129 22.5156 12.2129 21.9395V18.9512Z'
                        fill='#F5821F'
                      />
                    </svg>
                    <span className='pl-2'>{uploadText}</span>
                  </div>
                ) : (
                  <span>{uploadText}</span>
                )}
              </button>
              <button
                type='button'
                className='ml-4 bg-gray-level1 hover:bg-gray-level2 text-gray-text rounded-xl p-2'>
                Go Back
              </button>
              {uploadText === "Re-Upload" ? (
                <button
                  type='button'
                  className='ml-4 bg-gray-level1 hover:bg-gray-level2 text-gray-text rounded-xl p-2'>
                  Continue
                </button>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default UploadFilePartial;
