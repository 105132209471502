import React, { useState, useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { signIn } from "../store/actions/authActions";
import { logFirestoreEvent } from "../merlinv1/beta_api";
import MetaTags from "react-meta-tags";
import firebase from "firebase";

const signInFormSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const SignInPartial = (props) => {
  // Sets a loading state
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(signInFormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    console.log(data);
    await props.signIn(data);
  };

  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
    logScreenName("SignIn");
    logEvent("page_view", { page_name: "SignIn" });
  }, []);

  return (
    <div className='mt-16'>
      <div className='flex justify-center mb-12'>
        <div className=''>
          <div className='text-blue-accent w-auto md:text-4xl sm:text-3xl font-medium text-center'>
            Hello There!{" "}
          </div>
          <p className='w-full text-2xl text-gray-text2 font-medium text-center'>
            Sign in to your account below.{" "}
          </p>
          {props.user.authError ? (
            <div className='p-6 mx-3 mb-4 mt-4 border-2 border-red-accent text-red-accent rounded'>
              {props.user.authError}
            </div>
          ) : null}
          <form onSubmit={handleSubmit(onSubmit)} className=''>
            <div className='flex flex-wrap mb-12 mt-5 -mt-5 -mb-5'>
              <div className='flex flex-col w-full'>
                {/* <div className='flex flex-row'>
                  <span className='text-xl font-semibold'>Email</span>
                  <span className='text-red-orange'>*</span>
                </div> */}
                <input
                  className='w-full rounded-xl mb-8 py-2 pl-6 bg-gray-level1'
                  name='email'
                  label='Email'
                  placeholder='Email'
                  type='text'
                  required
                  ref={register}
                  errors={errors}
                />
                {/* <div className='flex flex-row'>
                  <span className='text-xl font-semibold'>Password</span>
                  <span className='text-red-orange'>*</span>
                </div> */}
                <input
                  className='w-full rounded-xl py-2 pl-6 pr-4 bg-gray-level1'
                  name='password'
                  label='Password'
                  placeholder='Password'
                  type='password'
                  required
                  ref={register}
                  errors={errors}
                />
              </div>

              <div className='flex flex-row w-full justify-center my-4'>
                <button
                  className='btn-sm text-blue-text bg-blue-level1 hover:bg-blue-level2 hover:text-blue-text2 rounded-xl'
                  type='submit'>
                  Sign in
                </button>
                <div className='mx-2'></div>
                <Link
                  to='/reset-password'
                  className='btn-sm text-gray-text bg-gray-level1 hover:bg-gray-level2 hover:text-gray-text2 rounded-xl'>
                  Forgot password?
                </Link>
              </div>
            </div>
          </form>
          {
            //  </Card>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: async (creds) => {
      const returnSignIn = await signIn(creds);
      dispatch(returnSignIn);
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignInPartial)
);
