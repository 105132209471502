import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextArea from "../pages/components/TextArea";
import { addToMailingList } from "../merlinv1/beta_api";

function BusinessContactPartial() {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isTextChecked, setIsTextChecked] = useState(false);
  const [isMusicChecked, setIsMusicChecked] = useState(false);
  const [isImageChecked, setIsImageChecked] = useState(false);
  const [isCodeChecked, setIsCodeChecked] = useState(false);
  const [isEducationChecked, setIsEducationChecked] = useState(false);
  const [isDiscountChecked, setIsDiscountChecked] = useState(false);
  const [questions, setQuestions] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const CompanySignUpFormSchema = Yup.object({
    firstname: Yup.string().required(
      "Person of contact's first name is required"
    ),
    lastname: Yup.string().required(
      "Person of contact's last name is required"
    ),
    phoneNum: Yup.string()
      .required("Phone Number is required")
      //.matches(/^[0-9]+$/, "Must be only digits"),
      .matches(/^[0-9]*$/, "Must be only digits"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    companyName: Yup.string()
      .required("Company Name is required")
      .max(100, "Company Name cannot be more than 100 letters"),
    industryType: Yup.string().required("This field is required"),
  });

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(CompanySignUpFormSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      phoneNum: "",
      email: "",
      companyName: "",
      industryType: "",
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    var res = await addToMailingList(
      data.firstname,
      data.lastname,
      data.email,
      data.companyName,
      data.phoneNum,
      data.industryType,
      data.timeZone,
      [
        isTextChecked,
        isMusicChecked,
        isImageChecked,
        isCodeChecked,
        isEducationChecked,
        isDiscountChecked,
      ],
      questions
    );
    setIsSuccess(res.data.success);
  };

  if (!isSuccess) {
    return (
      <section className='flex justify-center'>
        <div className='lg:w-1/2 md:w-full sm:w-full pt-16'>
          <div className='pt-6'>
            <div className='lg:text-4xl md:text-4xl sm:text-2xl text-blue-accent leading-tighter tracking-tighter text-center aos-init aos-animate w-full'>
              <div className='flex flex-col justify-center font-medium'>
                Schedule A Demo
              </div>
            </div>
            <div className='pt-4 pb-12 lg:text-xl md:text-xl sm:text-xl font-medium leading-tighter tracking-tighter text-center aos-init aos-animate w-full'>
              <div className='flex flex-col justify-center text-gray-level4'>
                Fill out the form below to speak with a Quasi team member.
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='p-6 mb-5'>
            <div className='flex flex-wrap -mx-3 mb-12 mt-5 -mt-5 -mb-5'>
              {error ? (
                <div className='text-sm w-full p-6 mx-3 mb-4 bg-red-200 border-2 border-red-accent text-red-accent rounded'>
                  {error}
                </div>
              ) : null}

              <div className='grid grid-cols-2 gap-4 mb-8 items-center w-full'>
                <div className='flex flex-col'>
                  {/* <span className='text-xl font-semibold'>
                    First Name
                    <span className='text-red-orange'>*</span>
                  </span> */}
                  <input
                    className={"w-full rounded-xl py-2 px-6 bg-gray-level1"}
                    name='firstname'
                    label='First Name'
                    placeholder='First Name *'
                    type='text'
                    required
                    ref={register}
                    errors={errors}
                  />
                  <p className='block text-sm font-medium text-red-accent'>
                    <ErrorMessage errors={errors} name='firstname' />
                  </p>
                </div>

                <div className='flex flex-col'>
                  {/* <span className='text-xl font-semibold'>
                    Last Name
                    <span className='text-red-orange'>*</span>
                  </span> */}
                  <input
                    className={"w-full rounded-xl py-2 px-6 bg-gray-level1"}
                    name='lastname'
                    label='Last Name'
                    placeholder='Last Name *'
                    type='text'
                    required
                    ref={register}
                    errors={errors}
                  />
                  <p className='block text-sm font-medium text-red-accent'>
                    <ErrorMessage errors={errors} name='lastname' />
                  </p>
                </div>
              </div>

              <div className='w-full mb-8'>
                {/* <span className='text-xl font-semibold'>
                  Work Email
                  <span className='text-red-orange'>*</span>
                </span> */}
                <input
                  className='w-full rounded-xl py-2 px-6 bg-gray-level1'
                  name='email'
                  label='Email'
                  placeholder='Work Email *'
                  type='text'
                  required
                  ref={register}
                  errors={errors}
                />
                <p className='block text-sm font-medium text-red-accent'>
                  <ErrorMessage errors={errors} name='email' />
                </p>
              </div>

              <div className='w-full mb-8'>
                {/* <span className='text-xl font-semibold'>
                  Company/Organization Name
                  <span className='text-red-orange'>*</span>
                </span> */}
                <input
                  className='w-full rounded-xl py-2 px-6 bg-gray-level1'
                  name='companyName'
                  label='companyName'
                  placeholder='Company/Organization'
                  type='text'
                  required
                  ref={register}
                  errors={errors}
                />
                <p className='block text-sm font-medium text-red-accent'>
                  <ErrorMessage errors={errors} name='companyName' />
                </p>
              </div>

              {/* <span className='text-xl font-semibold'>Work Phone Number</span> */}
              <input
                className='w-full rounded-xl py-2 px-6 bg-gray-level1 mb-8'
                name='phoneNum'
                label='Phone Number'
                placeholder='Work Phone Number'
                type='text'
                required
                ref={register}
                errors={errors}
                maxLength={15}
              />

              <div className='w-full mb-8'>
                {/* <span className='text-xl font-semibold'>
                  Select Industry
                  <span className='text-red-orange'>*</span>
                </span> */}
                <select
                  name='industryType'
                  ref={register}
                  className='p-2 w-full outline-gray-level1 rounded-xl bg-gray-level1'>
                  <option value='' selected disabled hidden>
                    Select Industry
                  </option>
                  <option>Adtech</option>
                  <option>Aerospace</option>
                  <option>Agriculture</option>
                  <option>Analytics</option>
                  <option>Augmented Reality</option>
                  <option>Biotech</option>
                  <option>Community</option>
                  <option>Construction</option>
                  <option>Continuing Education</option>
                  <option>Crypto / Blockchain</option>
                  <option>Developer Tools</option>
                  <option>E-Sports</option>
                  <option>Education</option>
                  <option>Energy</option>
                  <option>Entertainment</option>
                  <option>Financial Services</option>
                  <option>Fitness / Wellness</option>
                  <option>Food/Beverage</option>
                  <option>Freight</option>
                  <option>Gaming</option>
                  <option>Government</option>
                  <option>Hard Tech</option>
                  <option>Hardware</option>
                  <option>Healthcare</option>
                  <option>Insurance</option>
                  <option>Language Learning</option>
                  <option>Lending / Loan Management</option>
                  <option>Marketplacea</option>
                  <option>Media</option>
                  <option>Public Transportation</option>
                  <option>Retail</option>
                  <option>Recruiting/Talent</option>
                  <option>Robotics</option>
                  <option>Security</option>
                  <option>Sourcing / Recruiting</option>
                  <option>Transportation</option>
                  <option>Travel/Tourism</option>
                  <option>Virtual Reality</option>
                  <option>Other</option>
                </select>

                <p className='block text-sm font-medium text-red-accent'>
                  <ErrorMessage errors={errors} name='industryType' />
                </p>
              </div>

              <div className='w-full mb-8'>
                {/* <span className='text-xl font-semibold'>
                  Select Timezone
                  <span className='text-red-orange'>*</span>
                </span> */}
                <select
                  name='timeZone'
                  ref={register}
                  className='p-2 w-full focus:outline-none outline-none rounded-xl bg-gray-level1'>
                  <option value='' selected disabled hidden>
                    Select Timezone
                  </option>
                  <option>Eastern Time (US & Canada)</option>
                  <option>Central Time (US & Canada)</option>
                  <option>Mountain Time (US & Canada)</option>
                  <option>Pacific Time (US & Canada)</option>
                  <option>Alaska</option>
                  <option>Hawaii</option>
                </select>

                <p className='block text-sm font-medium text-red-accent'>
                  <ErrorMessage errors={errors} name='timeZone' />
                </p>
              </div>

              <div className='flex flex-col w-full'>
                <div className='flex flex-row py-2'>
                  <input
                    id='1'
                    type='checkbox'
                    checked={isTextChecked}
                    onChange={() => setIsTextChecked(!isTextChecked)}
                  />
                  <div className='w-full ml-2 text-gray-level4'>
                    Text/Writing Tools
                  </div>
                </div>
                <div className='flex flex-row py-2'>
                  <input
                    id='2'
                    type='checkbox'
                    checked={isImageChecked}
                    onChange={() => setIsImageChecked(!isImageChecked)}
                  />
                  <div className='w-full ml-2 text-gray-level4'>
                    Images/Art Tools
                  </div>
                </div>
                <div className='flex flex-row py-2'>
                  <input
                    id='3'
                    type='checkbox'
                    checked={isMusicChecked}
                    onChange={() => setIsMusicChecked(!isMusicChecked)}
                  />
                  <div className='w-full ml-2 text-gray-level4'>
                    Music Creation Tools
                  </div>
                </div>
                <div className='flex flex-row py-2'>
                  <input
                    id='4'
                    type='checkbox'
                    checked={isCodeChecked}
                    onChange={() => setIsCodeChecked(!isCodeChecked)}
                  />
                  <div className='w-full ml-2 text-gray-level4'>
                    Code Tools/API Creation
                  </div>
                </div>
                <div className='flex flex-row py-2'>
                  <input
                    id='5'
                    type='checkbox'
                    checked={isEducationChecked}
                    onChange={() => setIsEducationChecked(!isEducationChecked)}
                  />
                  <div className='w-full ml-2 text-gray-level4'>
                    Education Tools
                  </div>
                </div>
                <div className='flex flex-row py-2'>
                  <input
                    id='6'
                    type='checkbox'
                    checked={isDiscountChecked}
                    onChange={() => setIsDiscountChecked(!isDiscountChecked)}
                  />
                  <div className='w-full ml-2 text-gray-level4'>
                    Employee Group Discout For Marketplace
                  </div>
                </div>
              </div>
              <div className='mt-8 w-full'>
                <TextArea
                  className='w-full p-3 text-gray-text bg-gray-level1 rounded-lg whitespace-pre-line'
                  name='question'
                  type='search'
                  required
                  placeholder='Comments/Questions/Thoughts'
                  id='questions'
                  onChangeText={(newText) => {
                    setQuestions(newText);
                  }}
                  autoComplete='off'
                />
              </div>
              {error ? (
                <div className='text-sm w-full p-6 mx-3 mb-4 bg-red-200 border-2 border-red-accent text-red-accent rounded '>
                  {error}
                </div>
              ) : null}
              <div className='flex flex-row w-full justify-left mb-8 mt-12'>
                {isLoading ? (
                  <button className='btn-sm text-sm text-blue-accent bg-blue-level1 hover:bg-blue-level2 hover:text-blue-pondr border-2 border-blue-level1 hover:border-blue-level2 rounded-xl py-2 px-4'>
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 23 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='animate-spin-slow'>
                      <path
                        d='M12.2129 1.89062C12.2129 1.30469 11.7246 0.806641 11.1387 0.806641C10.5527 0.806641 10.0645 1.30469 10.0645 1.89062V4.87891C10.0645 5.45508 10.5527 5.95312 11.1387 5.95312C11.7246 5.95312 12.2129 5.45508 12.2129 4.87891V1.89062ZM5.29883 7.61328C5.70898 8.02344 6.41211 8.0332 6.8125 7.62305C7.23242 7.21289 7.23242 6.50977 6.82227 6.09961L4.76172 4.0293C4.35156 3.61914 3.6582 3.59961 3.24805 4.00977C2.83789 4.41992 2.82812 5.12305 3.23828 5.5332L5.29883 7.61328ZM15.3672 6.16797C14.9668 6.57812 14.9668 7.28125 15.377 7.69141C15.7871 8.10156 16.4902 8.0918 16.9004 7.68164L18.9707 5.61133C19.3809 5.20117 19.3809 4.49805 18.9609 4.07812C18.5605 3.67773 17.8574 3.67773 17.4473 4.08789L15.3672 6.16797ZM21.168 12.9844C21.7539 12.9844 22.252 12.4961 22.252 11.9102C22.252 11.3242 21.7539 10.8359 21.168 10.8359H18.1895C17.6035 10.8359 17.1055 11.3242 17.1055 11.9102C17.1055 12.4961 17.6035 12.9844 18.1895 12.9844H21.168ZM1.09961 10.8359C0.513672 10.8359 0.0253906 11.3242 0.0253906 11.9102C0.0253906 12.4961 0.513672 12.9844 1.09961 12.9844H4.09766C4.68359 12.9844 5.18164 12.4961 5.18164 11.9102C5.18164 11.3242 4.68359 10.8359 4.09766 10.8359H1.09961ZM16.9004 16.1289C16.4902 15.7285 15.7871 15.7285 15.3672 16.1387C14.9668 16.5488 14.9668 17.252 15.377 17.6621L17.457 19.7324C17.8672 20.1426 18.5703 20.1328 18.9805 19.7227C19.3906 19.3223 19.3809 18.6094 18.9707 18.1992L16.9004 16.1289ZM3.30664 18.1992C2.88672 18.6094 2.88672 19.3125 3.28711 19.7227C3.69727 20.1328 4.40039 20.1426 4.81055 19.7324L6.88086 17.6719C7.30078 17.2617 7.30078 16.5586 6.90039 16.1484C6.49023 15.7383 5.78711 15.7285 5.37695 16.1387L3.30664 18.1992ZM12.2129 18.9512C12.2129 18.3652 11.7246 17.8672 11.1387 17.8672C10.5527 17.8672 10.0645 18.3652 10.0645 18.9512V21.9395C10.0645 22.5156 10.5527 23.0137 11.1387 23.0137C11.7246 23.0137 12.2129 22.5156 12.2129 21.9395V18.9512Z'
                        fill='#003150'
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    className='btn-sm text-sm text-blue-accent bg-blue-level1 hover:bg-blue-level2 hover:text-blue-pondr border-2 border-blue-level1 hover:border-blue-level2 rounded-xl py-2 px-4'
                    type='submit'>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  } else {
    return (
      <section>
        <div className='px-8 pt-24'>
          <div className='pt-6'>
            <div className='flex flex-col items-center justify-center w-full'>
              <div className='text-center lg:text-4xl md:text-4xl sm:text-2xl leading-tighter tracking-tighter mb-4 text-left aos-init aos-animate w-full text-blue-accent font-medium'>
                Thanks!
              </div>
              <p className='text-center lg:text-xl md:text-xl sm:text-xl font-medium leading-tighter tracking-tighter mb-4 text-left aos-init aos-animate w-full'>
                A Quasi team member will be reaching out to you within the next
                few days.
              </p>
              <div className='flex flex-wrap justify-center items-center'>
                <Link
                  to='/'
                  className='mx-2 btn-sm font-medium text-blue-text bg-blue-level1 hover:text-blue-text2 hover:bg-blue-level2 focus:outline-none rounded-squircle my-2'>
                  <span className=''>Go Home</span>
                  <path
                    d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z'
                    fillRule='nonzero'
                  />
                </Link>
                <div
                  onClick={() => window.location.reload()}
                  className='mx-2 btn-sm font-medium text-gray-text bg-gray-level1 hover:text-gray-text2 hover:bg-gray-level2 focus:outline-none rounded-squircle my-2'>
                  <span className=''>Submit Another Request</span>
                  <path
                    d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z'
                    fillRule='nonzero'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BusinessContactPartial;
