import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { Link, withRouter, useHistory } from "react-router-dom";
import { getFile } from "../merlinv1/beta_api";

function FilePagePartial() {
  let searchParams = new URLSearchParams(window.location.pathname);

  const [platform, setPlatform] = useState("");
  const [useCase, setUseCase] = useState("");
  const [type, setType] = useState("");
  const [output, setOutput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [input, setInput] = useState("");

  const saveFile = (image) => {
    saveAs(image, "QuasiArt.png");
  };

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(output).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    });
  };

  const fetchData = async () => {
    try {
      var fileId;
      for (let p of searchParams) {
        fileId = p[1];
        break;
      }
      console.log(fileId);
      var fileObject;
      fileObject = await getFile(fileId);
      setPlatform(fileObject.data.platform);
      setUseCase(fileObject.data.use_case);
      setType(fileObject.data.type);
      setOutput(fileObject.data.output);
      setInput(fileObject.data.input);
      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return (
      <div className='w-full h-screen flex justify-center items-center'>
        <svg
          width='50'
          height='50'
          viewBox='0 0 23 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='animate-spin-slow'>
          <path
            d='M12.2129 1.89062C12.2129 1.30469 11.7246 0.806641 11.1387 0.806641C10.5527 0.806641 10.0645 1.30469 10.0645 1.89062V4.87891C10.0645 5.45508 10.5527 5.95312 11.1387 5.95312C11.7246 5.95312 12.2129 5.45508 12.2129 4.87891V1.89062ZM5.29883 7.61328C5.70898 8.02344 6.41211 8.0332 6.8125 7.62305C7.23242 7.21289 7.23242 6.50977 6.82227 6.09961L4.76172 4.0293C4.35156 3.61914 3.6582 3.59961 3.24805 4.00977C2.83789 4.41992 2.82812 5.12305 3.23828 5.5332L5.29883 7.61328ZM15.3672 6.16797C14.9668 6.57812 14.9668 7.28125 15.377 7.69141C15.7871 8.10156 16.4902 8.0918 16.9004 7.68164L18.9707 5.61133C19.3809 5.20117 19.3809 4.49805 18.9609 4.07812C18.5605 3.67773 17.8574 3.67773 17.4473 4.08789L15.3672 6.16797ZM21.168 12.9844C21.7539 12.9844 22.252 12.4961 22.252 11.9102C22.252 11.3242 21.7539 10.8359 21.168 10.8359H18.1895C17.6035 10.8359 17.1055 11.3242 17.1055 11.9102C17.1055 12.4961 17.6035 12.9844 18.1895 12.9844H21.168ZM1.09961 10.8359C0.513672 10.8359 0.0253906 11.3242 0.0253906 11.9102C0.0253906 12.4961 0.513672 12.9844 1.09961 12.9844H4.09766C4.68359 12.9844 5.18164 12.4961 5.18164 11.9102C5.18164 11.3242 4.68359 10.8359 4.09766 10.8359H1.09961ZM16.9004 16.1289C16.4902 15.7285 15.7871 15.7285 15.3672 16.1387C14.9668 16.5488 14.9668 17.252 15.377 17.6621L17.457 19.7324C17.8672 20.1426 18.5703 20.1328 18.9805 19.7227C19.3906 19.3223 19.3809 18.6094 18.9707 18.1992L16.9004 16.1289ZM3.30664 18.1992C2.88672 18.6094 2.88672 19.3125 3.28711 19.7227C3.69727 20.1328 4.40039 20.1426 4.81055 19.7324L6.88086 17.6719C7.30078 17.2617 7.30078 16.5586 6.90039 16.1484C6.49023 15.7383 5.78711 15.7285 5.37695 16.1387L3.30664 18.1992ZM12.2129 18.9512C12.2129 18.3652 11.7246 17.8672 11.1387 17.8672C10.5527 17.8672 10.0645 18.3652 10.0645 18.9512V21.9395C10.0645 22.5156 10.5527 23.0137 11.1387 23.0137C11.7246 23.0137 12.2129 22.5156 12.2129 21.9395V18.9512Z'
            fill='#1982C4'
          />
        </svg>
      </div>
    );
  } else if (type === "Text") {
    return (
      <section className='pt-16'>
        <div className='flex flex-row justify-center'>
          <div className='flex flex-col'>
            <span className='text-3xl text-center font-medium text-blue-accent'>
              {input}
            </span>
            <span className='text-center mt-8'>Platform: {platform}</span>
            <span className='text-center'>Use Case: {useCase}</span>
            <div className='bg-gray-level1 rounded-xl p-4 mx-32 mt-8'>
              <span className='text-blue-accent'>{output}</span>
            </div>
            <div className='flex justify-center'>
              <div className=''>
                {copied ? (
                  <button className='btn-sm text-gray-text bg-gray-level1 hover:bg-gray-level2 hover:text-gray-text2 rounded-xl mt-8'>
                    <svg
                      width='21'
                      height='21'
                      viewBox='0 0 21 21'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M10.1855 20.3711C15.7715 20.3711 20.3711 15.7715 20.3711 10.1855C20.3711 4.60938 15.7617 0 10.1758 0C4.59961 0 0 4.60938 0 10.1855C0 15.7715 4.59961 20.3711 10.1855 20.3711ZM10.1855 18.1348C5.78125 18.1348 2.24609 14.5898 2.24609 10.1855C2.24609 5.78125 5.77148 2.23633 10.1758 2.23633C14.5898 2.23633 18.125 5.78125 18.1348 10.1855C18.1445 14.5898 14.5898 18.1348 10.1855 18.1348ZM9.14062 14.834C9.51172 14.834 9.84375 14.6387 10.0586 14.3066L14.3555 7.63672C14.4922 7.42188 14.5996 7.17773 14.5996 6.95312C14.5996 6.42578 14.1406 6.07422 13.6426 6.07422C13.3203 6.07422 13.0371 6.25 12.8223 6.60156L9.11133 12.5586L7.37305 10.3809C7.13867 10.0781 6.89453 9.96094 6.5918 9.96094C6.07422 9.96094 5.6543 10.3711 5.6543 10.8984C5.6543 11.1426 5.75195 11.3672 5.92773 11.5918L8.18359 14.3164C8.45703 14.6582 8.75977 14.834 9.14062 14.834Z'
                        fill='#2C0147'
                      />
                    </svg>
                    <span className='pl-2'>Copied</span>
                  </button>
                ) : (
                  <button
                    className='btn-sm text-gray-text bg-gray-level1 hover:bg-gray-level2 hover:text-gray-text2 rounded-xl mt-8'
                    onClick={handleCopyClick}>
                    <svg
                      width='23'
                      height='22'
                      viewBox='0 0 23 22'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M3.79688 16.877H5.4375V18.332C5.4375 20.4805 6.58984 21.6133 8.74805 21.6133H18.6992C20.8574 21.6133 22.0098 20.4707 22.0098 18.332V8.32227C22.0098 6.18359 20.8574 5.04102 18.6992 5.04102H17.0586V3.58594C17.0586 1.44727 15.9062 0.314453 13.748 0.314453H3.79688C1.62891 0.314453 0.486328 1.44727 0.486328 3.58594V13.5957C0.486328 15.7441 1.62891 16.877 3.79688 16.877ZM3.98242 14.709C3.12305 14.709 2.66406 14.2695 2.66406 13.3711V3.82031C2.66406 2.92188 3.12305 2.48242 3.98242 2.48242H13.5625C14.4121 2.48242 14.8809 2.92188 14.8809 3.82031V5.04102H8.74805C6.58984 5.04102 5.4375 6.18359 5.4375 8.32227V14.709H3.98242ZM8.93359 19.4355C8.07422 19.4355 7.61523 19.0059 7.61523 18.1074V8.54688C7.61523 7.64844 8.07422 7.21875 8.93359 7.21875H18.5137C19.3633 7.21875 19.832 7.64844 19.832 8.54688V18.1074C19.832 19.0059 19.3633 19.4355 18.5137 19.4355H8.93359ZM9.22656 13.332C9.22656 13.8984 9.63672 14.3086 10.2129 14.3086H12.7422V16.8477C12.7422 17.4141 13.1523 17.8242 13.7188 17.8242C14.3047 17.8242 14.7148 17.4141 14.7148 16.8477V14.3086H17.2539C17.8301 14.3086 18.2305 13.8984 18.2305 13.332C18.2305 12.7461 17.8301 12.3359 17.2539 12.3359H14.7148V9.80664C14.7148 9.2207 14.3047 8.82031 13.7188 8.82031C13.1523 8.82031 12.7422 9.23047 12.7422 9.80664V12.3359H10.2129C9.63672 12.3359 9.22656 12.7461 9.22656 13.332Z'
                        fill='#333333'
                      />
                    </svg>
                    <span className='pl-2'>Copy Text</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else if (type === "Image") {
    return (
      <section className='pt-16 pb-16'>
        <div className='flex flex-row justify-center'>
          <div className='flex flex-col'>
            <span className='text-3xl text-center font-medium text-pink-accent'>
              {input}
            </span>
            <span className='text-center'>Style: {useCase}</span>
            <div className='bg-gray-level1 rounded-xl p-4 mx-32 mt-8'>
              {Array.isArray(output) ? (
                <div className='grid grid-cols-2 gap-4'>
                  {output.map((image, index) => (
                    <div onClick={() => saveFile(image)}>
                      <img
                        key={index}
                        className='darken-hover rounded-xl'
                        src={image}></img>
                    </div>
                  ))}
                </div>
              ) : (
                <img className='text-blue-accent rounded-xl' src={output}></img>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FilePagePartial;
