import TextArea from "../pages/components/TextArea";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

function HeroHome() {
  const [selectedItem, setSelectedItem] = useState("social");
  const history = useHistory();

  return (
    <section>
      <div className='flex flex-col pb-16'>
        <span className='text-4xl font-semibold text-center pt-16'>
          <span className='text-blue-accent'>Amplify</span> Your Digital Brand{" "}
          <br />
          with AI-Powered Content
        </span>
        <span className='text-center text-gray-text1 font-semibold'>
          Quasi Business is the workspace where <br /> creative content is made
          personalized to you.
        </span>
        <div
          className='flex flex-row justify-center'
          onClick={() => history.push("/business-contact")}>
          <div className='btn-sm bg-blue-text hover:bg-blue-level1 rounded-xl text-blue-level1 hover:text-blue-text p-2 mt-4'>
            Schedule A Demo
          </div>
        </div>
        <div className='flex flex-row justify-center'>
          <img
            src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Biz_Hero.webp'
            width={1250}
          />
        </div>
        <span className='text-center font-semibold pb-4'>
          Powering the world’s best teams, from next-generation startups to
          established enterprises.
        </span>
        <div className='flex flex-row justify-center'>
          <img
            src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Biz_Customers.webp'
            width={500}
          />
        </div>
      </div>

      <div className='flex flex-col mb-16'>
        <span className='text-4xl font-semibold text-center pt-16'>
          <span className='text-blue-accent'>Personalized</span> Content. For
          Every Platform.
        </span>
        <span className='text-center text-gray-text1 font-semibold'>
          To grow your online presence, our algorithms offer personalized <br />
          suggestions based on your preferences and information.
        </span>
        <div className='flex flex-row justify-center pt-4'>
          <img
            src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Biz_Platforms.webp'
            width={500}
          />
        </div>
      </div>

      <div className='flex flex-col pb-8'>
        <span className='text-4xl font-semibold text-center pt-16'>
          <span className='text-blue-accent'>Custom</span> Use Cases
        </span>
        <span className='text-center text-gray-text1 font-bold'>
          Have a specific workflow you want to enhance? The Quasi <br />
          team can develop an experience custom to your needs.
        </span>
        <div className='grid md:grid-cols-3 md:grid-rows-3 sm:grid-cols-1 gap-8 px-16 pt-4'>
          <div className='row-span-3 bg-gray-level1 rounded-xl p-4'>
            <div className='pb-4'>
              <svg
                width='22'
                height='28'
                viewBox='0 0 22 28'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M0.112793 23.3662C0.112793 26.064 1.52881 27.5044 4.21436 27.5044H17.2515C19.9248 27.5044 21.353 26.064 21.353 23.3662V5.01904C21.353 2.32129 19.9248 0.893066 17.2515 0.893066H4.21436C1.52881 0.893066 0.112793 2.32129 0.112793 5.01904V23.3662ZM2.83496 23.1343V5.25098C2.83496 4.18896 3.37207 3.60303 4.49512 3.60303H16.9707C18.0938 3.60303 18.6309 4.18896 18.6309 5.25098V23.1343C18.6309 24.2085 18.0938 24.7822 16.9707 24.7822H4.49512C3.37207 24.7822 2.83496 24.2085 2.83496 23.1343ZM13.7725 13.2344C15.1641 13.2344 16.1528 12.27 16.1528 10.8784C16.1528 9.52344 15.3105 8.63232 14.1021 8.63232C13.4917 8.63232 12.9912 8.82764 12.6494 9.25488H12.4053C12.4907 8.86426 12.7471 8.47363 13.1255 8.14404C13.4795 7.83887 13.9312 7.61914 14.4683 7.50928C14.8589 7.42383 15.0054 7.28955 15.0054 7.021C15.0054 6.76465 14.8223 6.58154 14.4927 6.58154C13.7236 6.58154 12.7837 7.05762 12.1489 7.729C11.4897 8.4248 11.1846 9.34033 11.1846 10.3047C11.1846 12.1602 12.3198 13.2344 13.7725 13.2344ZM7.88867 13.2466C9.28027 13.2466 10.2812 12.2822 10.2812 10.8784C10.2812 9.53564 9.42676 8.63232 8.21826 8.63232C7.60791 8.63232 7.11963 8.82764 6.76562 9.25488H6.52148C6.60693 8.86426 6.86328 8.47363 7.2417 8.15625C7.60791 7.83887 8.04736 7.61914 8.58447 7.52148C8.96289 7.44824 9.12158 7.30176 9.12158 7.021C9.12158 6.77686 8.93848 6.59375 8.60889 6.59375C7.85205 6.59375 6.92432 7.04541 6.26514 7.74121C5.61816 8.43701 5.30078 9.34033 5.30078 10.3169C5.30078 12.1602 6.44824 13.2466 7.88867 13.2466ZM6.375 17.3726H15.103C15.5547 17.3726 15.8843 17.0186 15.8843 16.5791C15.8843 16.1396 15.5547 15.7979 15.103 15.7979H6.375C5.91113 15.7979 5.56934 16.1396 5.56934 16.5791C5.56934 17.0186 5.91113 17.3726 6.375 17.3726ZM6.375 21.3521H10.6597C11.1113 21.3521 11.4531 21.0103 11.4531 20.5708C11.4531 20.1191 11.1113 19.7651 10.6597 19.7651H6.375C5.91113 19.7651 5.56934 20.1191 5.56934 20.5708C5.56934 21.0103 5.91113 21.3521 6.375 21.3521Z'
                  fill='#1982C4'
                />
              </svg>
            </div>
            <span className='text-xl font-medium'>Social Media Posts</span>
            <div className='text-medium text-gray-text1 font-medium'>
              Social Media Posts
            </div>
            <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Biz_Social_Media_Hero.webp' />
          </div>
          <div className='bg-gray-level1 rounded-xl p-4'>
            <div className='pb-4'>
              <svg
                width='26'
                height='26'
                viewBox='0 0 26 26'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M12.917 25.9185C19.8994 25.9185 25.6489 20.1689 25.6489 13.1865C25.6489 6.21631 19.8872 0.45459 12.9048 0.45459C5.93457 0.45459 0.185059 6.21631 0.185059 13.1865C0.185059 20.1689 5.93457 25.9185 12.917 25.9185ZM12.917 23.123C7.41162 23.123 2.99268 18.6919 2.99268 13.1865C2.99268 7.68115 7.39941 3.25 12.9048 3.25C18.4224 3.25 22.8413 7.68115 22.8535 13.1865C22.8657 18.6919 18.4224 23.123 12.917 23.123ZM12.917 20.8647C17.1162 20.8647 20.583 17.3735 20.583 13.1865C20.583 8.9873 17.1284 5.5083 12.917 5.5083C8.71777 5.5083 5.25098 8.99951 5.25098 13.1865C5.25098 17.3735 8.74219 20.8647 12.917 20.8647ZM12.917 18.3989C10.0972 18.3989 7.70459 16.0063 7.70459 13.1865C7.70459 10.3545 10.085 7.97412 12.917 7.97412C15.7368 7.97412 18.1294 10.3667 18.1294 13.1865C18.1294 16.0186 15.749 18.3989 12.917 18.3989ZM12.9292 16.1528C14.5405 16.1528 15.8833 14.8101 15.8833 13.1865C15.8833 11.5752 14.5405 10.2324 12.9292 10.2324C11.2935 10.2324 9.96289 11.563 9.96289 13.1865C9.96289 14.8101 11.3057 16.1528 12.9292 16.1528Z'
                  fill='#6A4C93'
                />
              </svg>
            </div>
            <span className='text-xl font-medium'>Campaigns</span>
            <div className='text-medium text-gray-text1 font-medium'>
              Learn More ->
            </div>
          </div>
          <div className='bg-gray-level1 rounded-xl p-4'>
            <div className='pb-4'>
              <svg
                width='29'
                height='28'
                viewBox='0 0 29 28'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M0.112793 14.0522C0.112793 17.0063 1.56543 18.8008 4.27539 19.2036L6.8999 25.002C7.97412 27.4067 9.91504 27.5898 11.2578 27.126C12.564 26.6743 13.3696 25.4414 13.04 23.7446L12.2466 19.7041C15.4814 20.3633 18.7163 21.5474 21.7681 23.293C22.2686 24.5503 23.4404 25.356 24.9297 25.356C26.9194 25.356 28.3354 23.9277 28.3354 21.9502V4.32324C28.3354 2.3457 26.9072 0.91748 24.9297 0.91748C23.3916 0.91748 22.1831 1.78418 21.7314 3.09033C18.0938 5.23877 13.4795 6.65479 9.80518 7.06982H5.54492C2.0293 7.06982 0.112793 8.92529 0.112793 12.3188V14.0522ZM24.063 21.9502V4.32324C24.063 3.81055 24.417 3.45654 24.9297 3.45654C25.4424 3.45654 25.7964 3.81055 25.7964 4.32324V21.9502C25.7964 22.4629 25.4424 22.8169 24.9297 22.8169C24.417 22.8169 24.063 22.4629 24.063 21.9502ZM10.3179 16.8354V9.51123C14.334 9.02295 18.1304 7.87549 21.5239 6.02002V20.3511C18.0693 18.459 14.3096 17.3237 10.3179 16.8354ZM5.54492 16.7622C3.44531 16.7622 2.65186 16.0176 2.65186 14.0522V12.3188C2.65186 10.3413 3.44531 9.60889 5.54492 9.60889H7.77881V16.7622H5.54492ZM9.3291 24.355L6.94873 19.2891H9.71973L9.74414 19.3013L10.6719 24.001C10.7451 24.4038 10.5376 24.6724 10.2446 24.7822C9.93945 24.8799 9.53662 24.7944 9.3291 24.355Z'
                  fill='#8AC926'
                />
              </svg>
            </div>
            <span className='text-xl font-medium'>Awareness</span>
            <div className='text-medium text-gray-text1 font-medium'>
              Learn More ->
            </div>
          </div>
          <div className='bg-gray-level1 rounded-xl p-4'>
            <div className='pb-4'>
              <svg
                width='28'
                height='22'
                viewBox='0 0 28 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M7.92529 16.1802C7.92529 16.937 8.41357 17.4131 9.18262 17.4131H11.7217C12.4907 17.4131 12.979 16.937 12.979 16.1802V1.2876C12.979 0.518555 12.4907 0.0424805 11.7217 0.0424805H9.18262C8.41357 0.0424805 7.92529 0.518555 7.92529 1.2876V16.1802ZM14.3584 16.1802C14.3584 16.937 14.8467 17.4131 15.6157 17.4131H18.1548C18.9238 17.4131 19.4243 16.937 19.4243 16.1802V4.09521C19.4243 3.32617 18.9238 2.8501 18.1548 2.8501H15.6157C14.8467 2.8501 14.3584 3.32617 14.3584 4.09521V16.1802ZM1.47998 16.1802C1.47998 16.937 1.98047 17.4131 2.74951 17.4131H5.28857C6.04541 17.4131 6.5459 16.937 6.5459 16.1802V6.56104C6.5459 5.79199 6.04541 5.31592 5.28857 5.31592H2.74951C1.98047 5.31592 1.47998 5.79199 1.47998 6.56104V16.1802ZM20.7915 16.1802C20.7915 16.937 21.292 17.4131 22.061 17.4131H24.6001C25.3569 17.4131 25.8574 16.937 25.8574 16.1802V8.95361C25.8574 8.19678 25.3569 7.7085 24.6001 7.7085H22.061C21.292 7.7085 20.7915 8.19678 20.7915 8.95361V16.1802ZM0.112793 20.6235C0.112793 21.4048 0.723145 21.9785 1.49219 21.9785H25.8574C26.6265 21.9785 27.2368 21.4048 27.2368 20.6235C27.2368 19.8301 26.6265 19.2563 25.8574 19.2563H1.49219C0.723145 19.2563 0.112793 19.8301 0.112793 20.6235Z'
                  fill='#FF595E'
                />
              </svg>
            </div>
            <span className='text-xl font-medium'>Metrics</span>
            <div className='text-medium text-gray-text1 font-medium'>
              Learn More ->
            </div>
          </div>
          <div className='bg-gray-level1 rounded-xl p-4'>
            <div className='pb-4'>
              <svg
                width='30'
                height='24'
                viewBox='0 0 30 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M4.25098 23.71H24.9907C27.6885 23.71 29.1289 22.2817 29.1289 19.6084V4.78906C29.1289 2.11572 27.6885 0.6875 24.9907 0.6875H4.25098C1.55322 0.6875 0.112793 2.10352 0.112793 4.78906V19.6084C0.112793 22.2939 1.55322 23.71 4.25098 23.71ZM2.83496 5.06982C2.83496 3.94678 3.40869 3.40967 4.48291 3.40967H24.7588C25.8208 3.40967 26.4067 3.94678 26.4067 5.06982V17.5332L20.6206 12.1133C20.0835 11.6372 19.4487 11.3809 18.8018 11.3809C18.1304 11.3809 17.5322 11.6128 16.9707 12.1011L11.4653 17.0205L9.21924 14.9941C8.70654 14.5425 8.14502 14.2983 7.57129 14.2983C6.99756 14.2983 6.50928 14.5303 5.99658 14.9819L2.83496 17.8018V5.06982ZM9.8418 12.5039C11.4653 12.5039 12.7959 11.1733 12.7959 9.5376C12.7959 7.91406 11.4653 6.55908 9.8418 6.55908C8.20605 6.55908 6.87549 7.91406 6.87549 9.5376C6.87549 11.1733 8.20605 12.5039 9.8418 12.5039Z'
                  fill='#FF76C7'
                />
              </svg>
            </div>
            <span className='text-xl font-medium'>Images</span>
            <div className='text-medium text-gray-text1 font-medium'>
              Learn More ->
            </div>
          </div>
          <div className='bg-gray-level1 rounded-xl p-4'>
            <div className='pb-4'>
              <svg
                width='28'
                height='24'
                viewBox='0 0 28 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M0.112793 22.8799C0.112793 23.5391 0.552246 23.9785 1.21143 23.9785H25.7964C26.5654 23.9785 27.1758 23.4048 27.1758 22.6235C27.1758 21.8301 26.5654 21.2563 25.7964 21.2563H3.16455C2.93262 21.2563 2.83496 21.1709 2.83496 20.9268V15.9219L7.77881 10.8071C7.88867 10.6973 7.99854 10.6362 8.09619 10.6362C8.19385 10.6362 8.31592 10.6973 8.41357 10.8071L12.6738 15.2383C13.2598 15.8486 13.8701 16.1294 14.5049 16.1294C15.1396 16.1294 15.7378 15.8486 16.3359 15.2383L21.3774 10.1724L23.2207 12.0278C23.8066 12.6138 24.6367 12.272 24.8564 11.4541L26.7119 4.23975C26.9072 3.48291 26.2358 2.82373 25.479 3.01904L18.2891 4.8623C17.459 5.08203 17.1172 5.8999 17.7031 6.48584L19.522 8.31689L14.8223 13.1143C14.7246 13.2119 14.6147 13.2729 14.5171 13.2729C14.4194 13.2729 14.2974 13.2241 14.1875 13.1143L9.93945 8.6709C9.34131 8.04834 8.74316 7.77979 8.09619 7.77979C7.46143 7.77979 6.8877 8.03613 6.26514 8.6709L2.83496 12.1743V1.79834C2.83496 1.0293 2.26123 0.418945 1.47998 0.418945C0.686523 0.418945 0.112793 1.0293 0.112793 1.79834V22.8799Z'
                  fill='#6A4C93'
                />
              </svg>
            </div>
            <span className='text-xl font-medium'>Trending Posts</span>
            <div className='text-medium text-gray-text1 font-medium'>
              Learn More ->
            </div>
          </div>
          <div className='bg-gray-level1 rounded-xl p-4'>
            <div className='pb-4'>
              <svg
                width='20'
                height='29'
                viewBox='0 0 20 29'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M10.2812 18.397C12.7837 18.397 14.7246 16.5171 14.7246 13.7339V5.48193C14.7246 2.69873 12.7837 0.818848 10.2812 0.818848C7.7666 0.818848 5.82568 2.69873 5.82568 5.48193V13.7339C5.82568 16.5171 7.7666 18.397 10.2812 18.397ZM0.588867 14.0635C0.588867 19.2759 4.08008 22.7793 9.01172 23.292V25.5381H4.56836C3.83594 25.5381 3.23779 26.124 3.23779 26.8442C3.23779 27.5645 3.83594 28.1626 4.56836 28.1626H15.9941C16.7144 28.1626 17.3247 27.5645 17.3247 26.8442C17.3247 26.124 16.7144 25.5381 15.9941 25.5381H11.5386V23.292C16.4824 22.7793 19.9614 19.2759 19.9614 14.0635V11.6953C19.9614 10.9751 19.3755 10.4136 18.6431 10.4136C17.9106 10.4136 17.3125 10.9751 17.3125 11.6953V13.978C17.3125 18.1284 14.4194 20.8628 10.2812 20.8628C6.13086 20.8628 3.25 18.1284 3.25 13.978V11.6953C3.25 10.9751 2.63965 10.4136 1.91943 10.4136C1.18701 10.4136 0.588867 10.9751 0.588867 11.6953V14.0635Z'
                  fill='#F5821F'
                />
              </svg>
            </div>
            <span className='text-xl font-medium'>Audio Content</span>
            <div className='text-medium text-gray-text1 font-medium'>
              Learn More ->
            </div>
          </div>
        </div>
        <div className='flex flex-col pb-16 mt-8'>
          <span className='text-4xl font-semibold text-center pt-16'>
            Get Started For <span className='text-blue-accent'>Free</span>
          </span>
          <span className='text-center text-gray-text1 font-semibold'>
            Play around with it first. Pay Later.
          </span>
          <div className='flex flex-row justify-center'>
            {/* <div className='btn-sm text-blue-text bg-blue-level1 rounded-xl hover:text-blue-level1 hover:bg-blue-text p-2 mt-4'>
              Try Quasi Free
            </div> */}
            <div
              className='btn-sm bg-blue-text hover:bg-blue-level1 rounded-xl text-blue-level1 hover:text-blue-text p-2 mt-4 ml-4'
              onClick={() => history.push("/business-contact")}>
              Schedule A Demo
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
