// this file will contain the Axios setup link between API and Front-end
import merlinApp from "../AxiosSetup.js";
import firebase from "firebase/app";
import "firebase/auth";

// POST request functions

export const getBlog = async (creationId) => {
  return merlinApp.post("/getBlog/" + creationId, {});
};

export const getBlogList = async (page) => {
  return merlinApp.post("/getBlogList", {
    data: {
      page: page,
    },
  });
};

export const makeBlog = async (user_input) => {
  return merlinApp.post("/makeBlog", {
    data: {
      user_input: user_input,
    },
  });
};

export const viewBlog = async (creationId) => {
  return merlinApp.post("/viewBlog", {
    data: {
      creationId: creationId,
    },
  });
};

export const generateDemo = async (modelID, input1, input2, input3) => {
  return merlinApp.post("/UseDemo", {
    data: {
      modelID: modelID,
      userInput1: input1,
      userInput2: input2,
      userInput3: input3,
    },
  });
};

export const addToMailingList = async (
  first_name,
  last_name,
  email,
  company_name,
  work_number,
  industry,
  time_zone,
  check_list,
  comments
) => {
  return merlinApp.post("/addToMail", {
    data: {
      first_name: first_name,
      last_name: last_name,
      email: email,
      company_name: company_name,
      work_number: work_number,
      industry: industry,
      time_zone: time_zone,
      check_list: check_list,
      comments: comments,
    },
  });
};

export const ContactSupport = async (email, area, issue) => {
  return merlinApp.post(
    "/ContactSupport",
    {
      data: {
        email: email,
        area: area,
        issue: issue,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getFile = async (output_id) => {
  return merlinApp.post("/getFile", {
    data: {
      output_id: output_id,
    },
  });
};

export const generateOutput = async (modelPlatform, modelUseCase, input1) => {
  return merlinApp.post(
    "/UseModels",
    {
      data: {
        modelPlatform: modelPlatform,
        modelUseCase: modelUseCase,
        userInput1: input1,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const generateImageOutput = async (input1, modelUseCase) => {
  return merlinApp.post(
    "/MakeImage",
    {
      data: {
        modelUseCase: modelUseCase,
        userInput1: input1,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const imageStatus = async (uuid, outputID) => {
  return merlinApp.post(
    "/imageStatus",
    {
      data: {
        uuid: uuid,
        outputID: outputID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const checkCache = async (platform) => {
  return merlinApp.post(
    "/checkCache",
    {
      data: {
        platform: platform,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const likeOutput = async (output_id) => {
  return merlinApp.post(
    "/likeOutput",
    {
      data: {
        output_id: output_id,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const dislikeOutput = async (output_id) => {
  return merlinApp.post(
    "/dislikeOutput",
    {
      data: {
        output_id: output_id,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const uploadFileToFirebase = async (file, name) => {
  return merlinApp.post(
    "/uploadFileToFirebase",
    { data: { file: file, name: name } },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const generateTweets = async () => {
  return merlinApp.get("/generateTweets", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const generateLinkedIn = async () => {
  return merlinApp.get("/generateLinkedIn", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const generateReddit = async () => {
  return merlinApp.get("/generateReddit", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const generateInstagram = async () => {
  return merlinApp.get("/generateInstagram", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const updateUserData = async (type, text) => {
  return merlinApp.post(
    "/updateUserData",
    {
      data: {
        type: type,
        text: text,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const regenerateTweet = async (tweet, reason) => {
  return merlinApp.post(
    "/regenerateTweet",
    {
      data: {
        tweet: tweet,
        reason: reason,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const regenerateInstagram = async (post, reason) => {
  return merlinApp.post(
    "/regenerateInstagram",
    {
      data: {
        post: post,
        reason: reason,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const regeneratePost = async (post, reason, platform) => {
  return merlinApp.post(
    "/regeneratePost",
    {
      data: {
        post: post,
        reason: reason,
        platform: platform,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const regenerateReddit = async (post, reason) => {
  return merlinApp.post(
    "/regenerateReddit",
    {
      data: {
        post: post,
        reason: reason,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const updateUserContent = async (platform, content) => {
  return merlinApp.post(
    "/updateUserContent",
    {
      data: {
        platform: platform,
        content: content,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getDashboard = async () => {
  return merlinApp.get("/getDashboard", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const getAccountDetails = async () => {
  return merlinApp.get("/getAccountDetails", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const generateAnyPost = async (platforms) => {
  return merlinApp.post(
    "/generateAnyPost",
    {
      data: {
        platforms: platforms,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const generateSpecificPost = async (
  platforms,
  link,
  image,
  postDescription,
  linkDescription,
  imageDescription
) => {
  return merlinApp.post(
    "/generateSpecificPost",
    {
      data: {
        platforms: platforms,
        link: link,
        image: image,
        postDescription: postDescription,
        linkDescription: linkDescription,
        imageDescription: imageDescription,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const generateBlogIdeas = async (tone, vertical, blogTopic) => {
  return merlinApp.post(
    "/generateBlogIdeas",
    {
      data: {
        tone: tone,
        vertical: vertical,
        blogTopic: blogTopic,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const generateCompleteBlog = async (topic, title, description, tone) => {
  return merlinApp.post(
    "/generateCompleteBlog",
    {
      data: {
        topic: topic,
        title: title,
        description: description,
        tone: tone,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const addBlogContext = async (blog) => {
  return merlinApp.post(
    "/addBlogContext",
    {
      data: {
        blog: blog,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const updateAccountDetails = async (
  first_name,
  last_name,
  email,
  company,
  keywords,
  competitors,
  twitterHandle,
  redditHandle,
  instagramHandle,
  youtubeHandle,
  linkedInHandle,
  tiktokHandle
) => {
  return merlinApp.post(
    "/updateAccountDetails",
    {
      data: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        company: company,
        keywords: keywords,
        competitors: competitors,
        twitterHandle: twitterHandle,
        redditHandle: redditHandle,
        instagramHandle: instagramHandle,
        youtubeHandle: youtubeHandle,
        linkedInHandle: linkedInHandle,
        tiktokHandle: tiktokHandle,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getFiles = async () => {
  return merlinApp.get("/getFiles", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const onboardUser = async (
  first_name,
  last_name,
  email,
  company,
  organization,
  industry,
  targetAudience,
  targetGeography,
  keywords,
  competitors,
  password,
  twitterHandle,
  instagramHandle,
  linkedInHandle,
  youtubeHandle,
  tiktokHandle,
  redditHandle
) => {
  return merlinApp.post("/onboardUser", {
    data: {
      first_name: first_name,
      last_name: last_name,
      email: email,
      company: company,
      organization: organization,
      industry: industry,
      targetAudience: targetAudience,
      targetGeography: targetGeography,
      keywords: keywords,
      competitors: competitors,
      password: password,
      twitterHandle: twitterHandle,
      instagramHandle: instagramHandle,
      linkedInHandle: linkedInHandle,
      youtubeHandle: youtubeHandle,
      tiktokHandle: tiktokHandle,
      redditHandle: redditHandle,
    },
  });
};

// Method is going to log a specific event in Cloud Firestore
export const logFirestoreEvent = (eventName, eventPaylod) => {
  if (window.location.host !== "www.letspondr.com") {
    if (eventName === "404 Error") {
      console.log({ eventName, eventPaylod });
    }
  } else {
    return merlinApp.post("/log", {
      data: {
        key: "RH5cFnBB0t",
        event_name: eventName,
        event_payload: eventPaylod,
      },
    });
  }
};
