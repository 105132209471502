import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import TwitterPartial from "../partials/TwitterPartial";

const Twitter = () => {
  useEffect(() => {}, []);
  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <MetaTags>
        <title>Quasi Business | Twitter</title>
        <meta name='description' content='Quasi Business | Studio' />
        <meta property='og:title' content='Quasi Business | Studio' />
      </MetaTags>
      <Header />
      <TwitterPartial />
      <div className='justify-end mt-auto'>
        <Footer />
      </div>
    </div>
  );
};

export default Twitter;
