import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./css/style.scss";
import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";
import UnprotectedRoute from "./partials/UnprotectedRoute";
// Landing pages:
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import BusinessContact from "./pages/BusinessContact";
import ResetPassword from "./pages/ResetPassword";

// Auth pages:
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import Create from "./pages/Create";
import Social from "./pages/Social";
import Photo from "./pages/Photo";
import Twitter from "./pages/Twitter";
import FilePage from "./pages/FilePage";

// Required StyleSheets
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "firebase/auth";
import ProtectedRoute from "./partials/ProtectedRoute";
import Onboarding from "./pages/Onboarding";
import AllFiles from "./pages/AllFiles";
import AccountDetails from "./pages/AccountDetails";
import ContactSupport from "./pages/ContactSupport";
import UploadFile from "./pages/UploadFile";
import LinkedIn from "./pages/LinkedIn";
import Reddit from "./pages/Reddit";
import Instagram from "./pages/Instagram";
import Studio from "./pages/Studio";
import SpecificPost from "./pages/SpecificPost";
import Blog from "./pages/Blog";

const App = (props) => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, []);

  return (
    <Switch>
      <Route exact path='/home' render={() => <Redirect to={"/"} />} />

      <Route exact path='/'>
        <Home />
      </Route>
      <Route exact path='/business-contact'>
        <BusinessContact />
      </Route>
      <Route exact path='/reset-password'>
        <ResetPassword />
      </Route>
      <Route exact path='/onboarding'>
        <Onboarding />
      </Route>
      {/* Protected routes are enables only when user signs in */}
      <UnprotectedRoute exact path='/signin'>
        <SignIn />
      </UnprotectedRoute>
      <ProtectedRoute exact path='/dashboard'>
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute exact path='/generate'>
        <Create />
      </ProtectedRoute>
      <ProtectedRoute exact path='/studio/new/*'>
        <Studio />
      </ProtectedRoute>
      <ProtectedRoute exact path='/studio/specific-post'>
        <SpecificPost />
      </ProtectedRoute>
      <ProtectedRoute exact path='/studio/blog'>
        <Blog />
      </ProtectedRoute>
      <ProtectedRoute exact path='/studio/social'>
        <Social />
      </ProtectedRoute>
      <ProtectedRoute exact path='/studio/photo'>
        <Photo />
      </ProtectedRoute>
      <ProtectedRoute exact path='/studio/twitter'>
        <Twitter />
      </ProtectedRoute>
      <ProtectedRoute exact path='/studio/instagram'>
        <Instagram />
      </ProtectedRoute>
      <ProtectedRoute exact path='/studio/linkedin'>
        <LinkedIn />
      </ProtectedRoute>
      <ProtectedRoute exact path='/studio/reddit'>
        <Reddit />
      </ProtectedRoute>
      <ProtectedRoute exact path='/file/*'>
        <FilePage />
      </ProtectedRoute>
      <ProtectedRoute exact path='/files'>
        <AllFiles />
      </ProtectedRoute>
      <ProtectedRoute exact path='/account'>
        <AccountDetails />
      </ProtectedRoute>
      <ProtectedRoute exact path='/support'>
        <ContactSupport />
      </ProtectedRoute>
      <ProtectedRoute exact path='/upload'>
        <UploadFile />
      </ProtectedRoute>
      {/* Page Not Found */}
      <Route exact path='/*'>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default App;
