import React, { useEffect } from "react";
import Wrapper from "../utils/Wrapper";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";
import BusinessContactPartial from "../partials/BusinessContactPartial";
import Headers from "../partials/Header";

function BusinessContact() {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
    logFirestoreEvent("BusinessContactView", {});
    logScreenName("BusinessContact");
    logEvent("page_view", { page_name: "Business Contact" });
  }, []);

  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <MetaTags>
        <title>Quasi Business Contact</title>
        <meta name='description' content='Quasi | Business Contact' />
        <meta property='og:title' content='Quasi | Business Contact' />
      </MetaTags>
      <Headers />
      <main>
        <BusinessContactPartial />
      </main>
    </div>
  );
}

export default BusinessContact;
