import React, { useEffect, useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { getFiles } from "../merlinv1/beta_api";

function FilePagePartial() {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [fileInputs, setFileInputs] = useState([]);

  const history = useHistory();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getFiles();
      console.log(response);
      setFileList(response.data.output_use_cases);
      setFileTypes(response.data.output_types);
      setFileIds(response.data.output_ids);
      setFileInputs(response.data.output_inputs);
      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className='w-full h-screen flex justify-center items-center'>
        <svg
          width='50'
          height='50'
          viewBox='0 0 23 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='animate-spin-slow'>
          <path
            d='M12.2129 1.89062C12.2129 1.30469 11.7246 0.806641 11.1387 0.806641C10.5527 0.806641 10.0645 1.30469 10.0645 1.89062V4.87891C10.0645 5.45508 10.5527 5.95312 11.1387 5.95312C11.7246 5.95312 12.2129 5.45508 12.2129 4.87891V1.89062ZM5.29883 7.61328C5.70898 8.02344 6.41211 8.0332 6.8125 7.62305C7.23242 7.21289 7.23242 6.50977 6.82227 6.09961L4.76172 4.0293C4.35156 3.61914 3.6582 3.59961 3.24805 4.00977C2.83789 4.41992 2.82812 5.12305 3.23828 5.5332L5.29883 7.61328ZM15.3672 6.16797C14.9668 6.57812 14.9668 7.28125 15.377 7.69141C15.7871 8.10156 16.4902 8.0918 16.9004 7.68164L18.9707 5.61133C19.3809 5.20117 19.3809 4.49805 18.9609 4.07812C18.5605 3.67773 17.8574 3.67773 17.4473 4.08789L15.3672 6.16797ZM21.168 12.9844C21.7539 12.9844 22.252 12.4961 22.252 11.9102C22.252 11.3242 21.7539 10.8359 21.168 10.8359H18.1895C17.6035 10.8359 17.1055 11.3242 17.1055 11.9102C17.1055 12.4961 17.6035 12.9844 18.1895 12.9844H21.168ZM1.09961 10.8359C0.513672 10.8359 0.0253906 11.3242 0.0253906 11.9102C0.0253906 12.4961 0.513672 12.9844 1.09961 12.9844H4.09766C4.68359 12.9844 5.18164 12.4961 5.18164 11.9102C5.18164 11.3242 4.68359 10.8359 4.09766 10.8359H1.09961ZM16.9004 16.1289C16.4902 15.7285 15.7871 15.7285 15.3672 16.1387C14.9668 16.5488 14.9668 17.252 15.377 17.6621L17.457 19.7324C17.8672 20.1426 18.5703 20.1328 18.9805 19.7227C19.3906 19.3223 19.3809 18.6094 18.9707 18.1992L16.9004 16.1289ZM3.30664 18.1992C2.88672 18.6094 2.88672 19.3125 3.28711 19.7227C3.69727 20.1328 4.40039 20.1426 4.81055 19.7324L6.88086 17.6719C7.30078 17.2617 7.30078 16.5586 6.90039 16.1484C6.49023 15.7383 5.78711 15.7285 5.37695 16.1387L3.30664 18.1992ZM12.2129 18.9512C12.2129 18.3652 11.7246 17.8672 11.1387 17.8672C10.5527 17.8672 10.0645 18.3652 10.0645 18.9512V21.9395C10.0645 22.5156 10.5527 23.0137 11.1387 23.0137C11.7246 23.0137 12.2129 22.5156 12.2129 21.9395V18.9512Z'
            fill='#1982C4'
          />
        </svg>
      </div>
    );
  } else {
    return (
      <section className='pt-16 pb-16'>
        <div className='flex flex-row justify-center'>
          <div className='flex flex-col'>
            <span className='text-blue-accent text-center font-medium text-4xl'>
              All Files
            </span>
            <span className='text-center'>
              Check out everything that Quasi has to offer you!
            </span>
          </div>
        </div>
        <div className='px-32 pt-16'>
          <div className='grid grid-cols-4 gap-4'>
            {fileList.map((file, index) => (
              <div onClick={() => history.push("/file/=" + fileIds[index])}>
                {fileTypes[index] === "Text" ? (
                  <div>
                    <div className='flex flex-row justify-center'>
                      <div className='p-6 rounded-xl bg-gray-level1 hover:bg-gray-level2'>
                        <svg
                          width='29'
                          height='29'
                          viewBox='0 0 26 26'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M21.3281 8.70605C23.623 8.70605 25.5396 6.80176 25.5396 4.50684C25.5396 2.19971 23.623 0.307617 21.3281 0.307617C19.0332 0.307617 17.1289 2.19971 17.1289 4.50684C17.1289 6.80176 19.0332 8.70605 21.3281 8.70605ZM0.612793 18.3008C0.612793 20.6323 0.881348 22.3535 2.13867 23.6108C3.4082 24.8682 5.12939 25.1489 7.46094 25.1489H16.7261C19.0454 25.1489 20.7788 24.8682 22.0483 23.6108C23.3057 22.3413 23.5742 20.6323 23.5742 18.3008V10.1587C22.9395 10.4272 22.2314 10.5615 21.499 10.5493C21.2793 10.5615 21.0596 10.5371 20.8521 10.5005V18.6426C20.8521 19.9243 20.6812 20.9741 20.0586 21.6211C19.4238 22.2559 18.3374 22.4268 17.0679 22.4268H7.11914C5.84961 22.4268 4.76318 22.2437 4.12842 21.6211C3.49365 20.9741 3.33496 19.9243 3.33496 18.6426V8.7915C3.33496 7.48535 3.49365 6.42334 4.12842 5.78857C4.76318 5.15381 5.84961 4.98291 7.14355 4.98291H15.3223C15.2856 4.76318 15.2734 4.55566 15.2734 4.33594C15.2612 3.60352 15.4077 2.89551 15.6641 2.26074H7.43652C5.1416 2.26074 3.396 2.5415 2.13867 3.79883C0.881348 5.06836 0.612793 6.77734 0.612793 9.07227V18.3008Z'
                            fill='#1982C4'
                          />
                        </svg>
                      </div>
                    </div>
                    <div className=''>
                      <div className='text-medium text-md text-gray-text text-center'>
                        {fileInputs[index]}
                      </div>
                    </div>
                  </div>
                ) : fileTypes[index] === "Image" ? (
                  <div>
                    <div className='flex flex-row justify-center'>
                      <div className='p-6 rounded-xl bg-gray-level1 hover:bg-gray-level2'>
                        <svg
                          width='29'
                          height='29'
                          viewBox='0 0 36 29'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M5.54883 28.252H30.4365C33.6738 28.252 35.4023 26.5381 35.4023 23.3301V5.54688C35.4023 2.33887 33.6738 0.625 30.4365 0.625H5.54883C2.31152 0.625 0.583008 2.32422 0.583008 5.54688V23.3301C0.583008 26.5527 2.31152 28.252 5.54883 28.252ZM3.84961 5.88379C3.84961 4.53613 4.53809 3.8916 5.82715 3.8916H30.1582C31.4326 3.8916 32.1357 4.53613 32.1357 5.88379V20.8398L25.1924 14.3359C24.5479 13.7646 23.7861 13.457 23.0098 13.457C22.2041 13.457 21.4863 13.7354 20.8125 14.3213L14.2061 20.2246L11.5107 17.793C10.8955 17.251 10.2217 16.958 9.5332 16.958C8.84473 16.958 8.25879 17.2363 7.64355 17.7783L3.84961 21.1621V5.88379ZM12.2578 14.8047C14.2061 14.8047 15.8027 13.208 15.8027 11.2451C15.8027 9.29688 14.2061 7.6709 12.2578 7.6709C10.2949 7.6709 8.69824 9.29688 8.69824 11.2451C8.69824 13.208 10.2949 14.8047 12.2578 14.8047Z'
                            fill='#FF76C7'
                          />
                        </svg>
                      </div>
                    </div>
                    <div className=''>
                      <div className='text-medium text-md text-gray-text text-center'>
                        {fileInputs[index]}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default FilePagePartial;
